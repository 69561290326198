import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { ApolloProvider } from '@apollo/client';
import client from './Api/ApiClient';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './Styles/index.scss';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

library.add(fas);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ErrorBoundary fallback={<></>}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </ErrorBoundary>
            </BrowserRouter>
        </QueryClientProvider>
    </ApolloProvider>,
);
